import React, { useState, useEffect } from 'react';

const RandomWordText = ({ staticText, words }) => {
  const [randomWord, setRandomWord] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * words.length);
    setRandomWord(words[randomIndex]);
  }, [words]);

  return (
    <p>
      {staticText} <strong>{randomWord}</strong>
    </p>
  );
};

export default RandomWordText;