// В другом компоненте, например, в странице с правилами
import React, { useState, useEffect } from 'react';
import defaultBackground from '../img/Rules.png';
import tabletBackground from '../img/RulesTablet.png';
import mobileBackground from '../img/RulesMobile.png';
import HomeNavi from '../components/HomeNavi';
import RulesSlider from '../components/RulesSlider';
import '../css/RulesPage.css';
// В главном файле стилей или компоненте
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OrientationNotification from '../components/PhoneOrientation';


const RulesPage = () => {
    const [backgroundImage, setBackgroundImage] = useState(defaultBackground);
    

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 860) {
          setBackgroundImage(mobileBackground);
        } else if (window.innerWidth <= 1024) {
          setBackgroundImage(tabletBackground);
        } else {
          setBackgroundImage(defaultBackground);
        }
      };
  
      // Вызов при монтировании и обновлении
      handleResize();
  
      // Подписка и отписка на событие изменения размера окна
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const divRules = {
      backgroundImage: `url(${backgroundImage})`,
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      width: '100vw',
    };
    return (
      <>
      <OrientationNotification/>
        <div className='background-wrapper'>
          <div className='background-block'>
            <div style={divRules}>
              <HomeNavi></HomeNavi>
              <RulesSlider />
            </div>
          </div>
        </div>

      </>

    );
};

export default RulesPage;
