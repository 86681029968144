// Ваш CustomModal компонент
import React from 'react';
import '../css/custom-modal.css'

const CustomModal = ({ onClose, children }) => {
  return (
    <div className="custom-modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        {children}
        <br></br>
        <button className="close-button" onClick={onClose}>
          Закрыть
        </button>
      </div>
    </div>
  );
};


export default CustomModal;
