import React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomModal from '../components/CustomModal';
import ResponsiveImage from '../components/ResponsiveImage';
import '../css/ResultPage.css'; // Убедитесь, что этот файл стилей подключен
import '../css/custom-modal.css';


const ResultPage = ({ location }) => {
  
  const { name, phone, selectedWord } = location.state || {};
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Make a POST request to the server
      await axios.post('https://parkent-api.repost.uz/submit-form', { name, phone, selectedWord });
      // await axios.post('http://localhost:3001/submit-form', { name, phone, selectedWord });

      // Set the text for the modal
      setModalText(
        'Ваш подарок сохранен! После покупки квартиры в ЖК “Parkent Gardens” вы его обязательно получите *за полными условиями акции обращайтесь в офис продаж'
      );

      // Open the modal
      setShowModal(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const closeModal = () => {
    // Close the modal
    setShowModal(false);
    // Redirect to the result page
    history.push('/result-page');
  };

  const handleClaimPrize = () => {
    // Redirect to the result page
    history.push('/result-page');
  };

  return (
    <div className="result-page">
      <div className="result-container">
        <div>
          <form onSubmit={handleSubmit}>
            <h1> 
            Поздравляем, вы выиграли {selectedWord}
            </h1>
            <p>Parkent Gardens - жилой комплекс на Циолковском. Квартиры от 68 до 107 м2, высота потолков 3,2 м, 8 этажей, охраняемая территория. Внутри комплекса: киноклуб, work out zone, детская площадка, автомойка. Цены от 11 млн. за 1 м2, действует гибридная рассрочка на 24 месяца.</p>
            <button className="claim-prize-button"  >Нажмите кнопку и сохраните свой подарок</button>
            {showModal && <CustomModal onClose={closeModal}>{modalText}</CustomModal>}
          </form>
        </div>
        <div>
            <ResponsiveImage/>
        </div>
        <div style={{display:'none'}}>           
        <p>{name}</p>
        <p>{phone}</p>
      </div>
      </div>
      <div>
      <Link to='/game-second'>
          <button>Сыграть еще раз!</button>
        </Link>
      </div>
    </div>
  );
};

export default ResultPage;
