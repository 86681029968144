// DataTable.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

const DataTable = () => {
  const [formData, setFormData] = useState([]);
  

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get('https://parkent-api.repost.uz/api/data');
        // const response = await axios.get('http://localhost:3001/api/data');
        setFormData(response.data);
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
    };

    fetchFormData();
  }, []);

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(formData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'FormData');
    XLSX.writeFile(wb, 'form-data.xlsx');
  };

  if (!formData || formData.length === 0) {
    return <p>No form data available.</p>;
  }

  const columns = Object.keys(formData[0]);

  return (
    <div>
      <button onClick={downloadExcel}>Download Excel</button>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {formData.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
