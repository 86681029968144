import React, { useState } from 'react';
import '../css/ResultPage.css'; // Убедитесь, что этот файл стилей подключен
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import nicerty from '../img/nicerty.png'



const ResultPage = () => {
    const pages = ['/game-second', '/game-third', '/game'];
    const randomIndex = Math.floor(Math.random() * pages.length);


  return (
    <div className="result-page">
      <div className="result-container-try">
        <div className='col-xs-4'>
            <h1> 
            Поздравляем, вы почти стали профессиональным сыщиком!
            </h1>
            <br></br>
            <p>Дарим промокод на получение скидочной карты на технику при покупке квартиры в ЖК "Parkent Gardens"</p>
            <br></br>
            <p>Попробуйте сыграть еще раз и у вас обязательно получится!</p>
            <button className="claim-prize-button">REPOSTNY</button>
        </div>
        <div>
            <img className='nicetry' src={nicerty}></img>
        </div>
      </div>
      <div>
      <Link to={pages[randomIndex]}>
          <button>Сыграть еще раз!</button>
        </Link>
      </div>
    </div>
  );
};

export default ResultPage;
