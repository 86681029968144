import React, { useEffect, useState } from 'react';
import bgImage from '../img/rotateImg.png';

const OrientationNotification = () => {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    <div 
    className='bg-img'
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: isLandscape ? 'none' : 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: '24px',
      zIndex: 9999,
    }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{ flex: 1}}>
                <div style={{justifyContent:'center', textAlign:'center', alignItems: 'center', fontWeight:'bold', paddingBlock:'25vh'}}>
                    <p>Переверните свой гаджет для того, чтобы продолжить игру</p>
                </div>
            </div>
            <div style={{ flex: 1}}>
                <div style={{justifyContent:'center', textAlign:'center', alignItems: 'center', fontWeight:'bold', paddingTop:'5vh'}}>
                    <img  
                        src={bgImage}
                        style={{width:'15vh'}}
                        ></img>
                </div>
            </div>
        </div>
       
        
      
    </div>
  );
};

export default OrientationNotification;
