import React, { useState, useEffect } from 'react';
import largeImage from '../img/180px.png';
import smallImage from '../img/120.png';

const ResponsiveImage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => setWindowWidth(window.innerWidth);
  
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentImage = windowWidth < 768 ? smallImage : largeImage;

  return <img src={currentImage} alt="подарок" />;
};

export default ResponsiveImage;
