import React, { useState, useEffect } from 'react';
import defaultBackground from '../img/homepage.png';
import tabletBackground from '../img/homepage_tablet.png';
import mobileBackground from '../img/homepage_mobile.png';
import HomeNavi from '../components/HomeNavi';
import '../css/HomePage.css';
import oferta from '../css/oferta.docx' 

const HomePage = () => {
    const [backgroundImage, setBackgroundImage] = useState(defaultBackground);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 480) {
          setBackgroundImage(mobileBackground);
        } else if (window.innerWidth <= 768) {
          setBackgroundImage(tabletBackground);
        } else {
          setBackgroundImage(defaultBackground);
        }
      };
  
      // Вызов при монтировании и обновлении
      handleResize();
  
      // Подписка и отписка на событие изменения размера окна
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const divStyle = {
      backgroundImage: `url(${backgroundImage})`,
      // Другие стили...
    };
    return (
        <div style={divStyle}>
            <HomeNavi />
            <div className="home-page">
                <div className="content">
                    <h1>Получите приятный подарок к Новому году от Parkent Gardens, сыграв в небольшую игру!</h1>
                    <a href='/rules'>
                        <button>ИГРАТЬ</button>
                    </a>
                    <br></br>
                    <br></br>
                    <a href={oferta} download="offer.pdf">
                      Скачать оферту
                    </a>
                 </div> 
            </div>
        </div>
    );
};

export default HomePage;
