// src/RulesSlider.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../css/custom.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useHistory } from 'react-router-dom'; 

const RulesSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const pages = ['/game', '/game-second', '/game-third'];
    const randomIndex = Math.floor(Math.random() * pages.length);


    const settings = {
        dots: false, // Уберите точки, если они вам не нужны
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: current => setCurrentSlide(current)
    };

    return (
        <div>
            <div style={{justifyContent: 'center'}} className="rules-slider-container">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="rule-box">
                            <div className='rules-text'>
                                <h3>Правило 1/3</h3>
                            </div>
                            <div className='rules-desc'>
                                <h2>В жилом комплексе Parkent Gardens спрятались несколько Санта-Клаусов</h2>
                            </div>
                            
                        </div>
                    </div>
                    <div className="slide">
                        <div className="rule-box">
                        <div className='rules-text'>
                                <h3>Правило 2/3</h3>
                            </div>
                            <div className='rules-desc'>
                                <h2>Ваша задача – найти 5 Санта-Клаусов. При клике на них вы получаете две секунды дополнительного времени</h2>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="rule-box">
                        <div className='rules-text'>
                                <h3>Правило 3/3</h3>
                            </div>
                            <div className='rules-desc'>
                                <h2>Поторопитесь и мы превратим ваш виртуальный подарок в реальный.</h2>
                            </div>
                        </div>
                    </div>
                    {/* Добавьте дополнительные слайды */}
                </Slider>
                {currentSlide >= 2 && ( // Показать кнопку после третьего слайда
                    <div className="button-container">
                        <Link to={pages[randomIndex]}>
                            <button className="start-game-button">
                                НАЧАТЬ ИГРУ
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

// Компоненты для стрелок
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-next" onClick={onClick} />
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-prev" onClick={onClick} />
    );
}

export default RulesSlider;
