// src/Header.js
import React, { useState, useEffect } from 'react';
import defaultImage from '../img/logo.svg';
import tabletImage from '../img/logo_mobile.png';
import mobileImage from '../img/logo_mobile.png';



const Header = ({ timeLeft, foundSantas }) => {
    const [imageSrc, setImageSrc] = useState(defaultImage);

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth <= 860) {
          setImageSrc(mobileImage);
        } else if (window.innerWidth <= 1024) {
          setImageSrc(tabletImage);
        } else {
          setImageSrc(defaultImage);
        }
      }
  
      window.addEventListener('resize', handleResize);
      handleResize(); // вызов при монтировании для инициализации
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <div style={{ marginLeft:'120px', color: 'red', fontWeight: 'bold' }}>
                <h2>
                    00:{timeLeft} 
                </h2>
            </div>
            <a href to="/">
                <img src={imageSrc} alt="Parkent Gardens" />
            </a>
            <div style={{ marginRight:'120px', color: 'white', fontWeight: 'bold' }}> 
                <h2>
                    {foundSantas}/{5}
                </h2>
            </div>
        </div>
    );
};

export default Header;
