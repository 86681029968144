import React, {useState} from 'react';
import spinnerGif from '../img/Spinner-5.gif'
import defaultBackground from '../img/Rules.png';

const Preloader = () => {
    const [backgroundImage] = useState(defaultBackground);

    const divLoader = {
        backgroundImage: `url(${backgroundImage})`,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center', // Центрирование вертикально
        justifyContent: 'center', // Центрирование горизонтально
      };
      const h1Style = {
        textAlign: 'center', // Выравнивание текста по центру
      };
    
  return (
    <div className="preloader" style={divLoader}>
      {/* Здесь можете использовать любую анимацию или изображение загрузчика */}
      <h1 style={h1Style}>Загрузка...</h1>
    </div>
  );
};

export default Preloader;