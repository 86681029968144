// import { Link } from 'react-router-dom';
// import React, { useState } from 'react';
// import axios from 'axios';
// import '../css/FormPage.css'; // Убедитесь, что этот файл стилей подключен
// import RandomWordText from '../components/RandomWordText';

// const FormPage = () => {
//   const [name, setName] = useState('');
//   const [phone, setPhone] = useState('');
//   const [text, setText] = useState('');
//   const [randomText, setRandomText] = useState('');
  

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       // Make a POST request to the server
//       await axios.post('http://localhost:3001/submit-form', { name, phone });

//       // Continue to the result page
//       window.location.href = '/result-page';
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   const staticPart = "Поздравляем, вы выиграли";
//   const wordList = [
//     "iPhone 15 (128 Gb)", 
//     "Apple Watch Ultra", 
//     "Dyson Фен", 
//     "Dyson V11 SV17 Absolute Extra Pro",
//     "Кофемашина De Longhi Ecam",
//     "iPad 11 inch M2/2022 (256 Gb)",
//     "iPad Air 5th gen 2022 (256 Gb)",
//     "Sony PlayStation PS5"
//     // ... Добавьте больше слов, всего до 20 слов
//   ];

//   const handleRandomTextChange = (newText) => {
//     setRandomText(newText);
//   };

//   return (
//     <div className="form-page">
//     <div className='form-container'>
//       <form onSubmit={handleSubmit}>
//         <h3>Заполните данные прежде, чем увидеть ваш результат</h3>
//         <input
//           type="text"
//           placeholder="Ваше имя"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Номер телефона"
//           value={phone}
//           onChange={(e) => setPhone(e.target.value)}
//         />
//           <div>
//             <RandomWordText
//               staticText={staticPart}
//               words={wordList}
//               onChange={handleRandomTextChange}
//             />
//           </div>
//           <h1 style={{color:'black'}}>--{randomText}</h1>
//         <Link to={{pathname: '/result-page', state: {name, phone, randomText}}}>
//           <button type="submit">ПРОДОЛЖИТЬ</button>
//         </Link>
        
//       </form>
//       </div>
//     </div>
//   );
// };

// export default FormPage;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import '../css/FormPage.css'; 
// import RandomWordText from '../components/RandomWordText';

// const FormPage = () => {
//   const [name, setName] = useState('');
//   const [phone, setPhone] = useState('');


//   // const handleSubmit = async (event) => {
//   //   event.preventDefault();

//   //   try {
//   //     // Make a POST request to the server
//   //     await axios.post('http://localhost:3001/submit-form', { name, phone, text, randomText });

//   //     // Continue to the result page
//   //     window.location.href = '/result-page';
//   //   } catch (error) {
//   //     console.error('Error submitting form:', error);
//   //   }
//   // };

//   const staticPart = "Поздравляем, вы выиграли";
//   const wordList = [
//     "iPhone 15 (128 Gb)", 
//     "Apple Watch Ultra", 
//     "Dyson Фен", 
//     "Dyson V11 SV17 Absolute Extra Pro",
//     "Кофемашина De Longhi Ecam",
//     "iPad 11 inch M2/2022 (256 Gb)",
//     "iPad Air 5th gen 2022 (256 Gb)",
//     "Sony PlayStation PS5"
//     // ... Добавьте больше слов, всего до 20 слов
//   ];
    

//   return (
//     <div className="form-page">
//       <div className='form-container'>
//         <form onSubmit={handleSubmit}>
//           <h3>Заполните данные прежде, чем увидеть ваш результат</h3>
//           <input
//             type="text"
//             placeholder="Ваше имя"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//           />
//           <input
//             type="text"
//             placeholder="Номер телефона"
//             value={phone}
//             onChange={(e) => setPhone(e.target.value)}
//           />
//           <div>
//             <RandomWordText
//               staticText={staticPart}
//               words={wordList}
//             />
//           </div>
//           <Link to={{
//             pathname: '/result-page',
//             state: { name, phone }
//           }}>
//             <button type="submit">ПРОДОЛЖИТЬ</button>
//           </Link>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default FormPage;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../css/FormPage.css'; 
import RandomWordText from '../components/RandomWordText';

const FormPage = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedWord, setSelectedWord] = useState('');

  const staticPart = "Поздравляем, вы выиграли";
  const wordList = [
    "iPhone 15",
    "Apple Watch",
    "Dyson Фен", 
    "Dyson V11 SV17 Absolute Extra Pro",
    "Кофемашина De Longhi Ecam",
    "iPad Air", 
    "Sony PlayStation 5",
    "MacBook Air",
  ];

  useEffect(() => {
    // Выбираем случайное слово из списка при монтировании компонента
    const randomIndex = Math.floor(Math.random() * wordList.length);
    const initialSelectedWord = wordList[randomIndex];
    setSelectedWord(initialSelectedWord);
  }, [wordList]);

  const handleRandomTextChange = (randomText) => {
    setSelectedWord(randomText);
  };

  return (
    <div className="form-page-2">
      <div className='form-container'>
        
          <h3>Заполните данные прежде, чем увидеть ваш результат</h3>
          <input
            type="text"
            placeholder="Имя и Фамилия"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Номер телефона"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div style={{display:'none'}}>
            <RandomWordText
              staticText={staticPart}
              words={wordList}
              initialSelectedWord={selectedWord}
              onChange={handleRandomTextChange}
            />
          </div>
          <Link to={{
            pathname: '/result-page-1',
            state: { name, phone, selectedWord }
          }}>
            <button type="submit">ПРОДОЛЖИТЬ</button>
          </Link>
      </div>
    </div>
  );
};

export default FormPage;
