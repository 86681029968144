// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Game from './pages/Game';
import FormPage from './pages/FormPage';
import TryAgain from './pages/TryAgain';
import HomePage from './pages/HomePage';
import GameSecond from './pages/GameSecond';
import GameThird from './pages/GameThird';
import RulesPage from './pages/RulesPage';
import ResultPage from './pages/ResultPage';
import ResultPage1 from './pages/ResultPage-1';
import ResultPage2 from './pages/ResultPage-2';
import FormPage2 from './pages/FormPage-2';
import FormPage3 from './pages/FormPage-3';
import TryAgain2 from './pages/TryAgainSecond';
import TryAgain3 from './pages/TryAgainThird';
import NiceTry from './pages/NiceTry';
import Table from './pages/Table';


const App = () => {
    const [allSantasFound, setAllSantasFound] = useState(false);
    const [allSantaFound, setAllSantaFound] = useState(false);
    const [allSantsFound, setAllSantsFound] = useState(false);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [timeUp, setTimeUp] = useState(false);
    const [timerUp, setTimerUp] = useState(false);

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/rules" component={RulesPage}></Route>
                <Route path="/form" component={FormPage} />
                <Route path="/try-again" component={TryAgain} />
                <Route path="/result-page" component={ResultPage} />
                <Route path="/result-page-1" component={ResultPage1} />
                <Route path="/result-page-2" component={ResultPage2} />
                {/* <Route path="/nice-try" component={NiceTry} /> */}
                <Route path="/game">
                    <Game 
                        onAllSantasFound={() => setAllSantasFound(true)} 
                        onTimeIsUp={() => setTimeIsUp(true)} 
                    />
                    {allSantasFound && <Redirect to="/form" />}
                    {timeIsUp && !allSantasFound && <Redirect to="/try-again" />}
                </Route>
                

                <Route path="/form-second" component={FormPage2} />
                {/* <Route path="/try-again-2" component={TryAgain2} /> */}
                <Route path="/game-second">
                    <GameSecond
                        onAllSantaFound={() => setAllSantaFound(true)} 
                        onTimeUp={() => setTimeUp(true)} 
                    />
                    {allSantaFound && <Redirect to="/form" />}
                    {timeUp && !allSantaFound && <Redirect to="/try-again" />}
                </Route>
                

                <Route path="/form-third" component={FormPage3} />
                {/* <Route path="/try-again-3" component={TryAgain3} /> */}
                <Route path="/game-third" component={GameThird}>
                    <GameThird
                        onAllSantsFound={() => setAllSantsFound(true)} 
                        onTimerUp={() => setTimerUp(true)} 
                    />
                    {allSantasFound && <Redirect to="/form" />}
                    {timerUp && !allSantsFound && <Redirect to="/try-again" />}
                </Route>
                <Route path="/data-table" component={Table}></Route>
            </Switch>
        </Router>
    );
};

export default App;
