import React, { useState, useEffect } from 'react';
import defaultImage from '../img/logo.svg';
import tabletImage from '../img/logo_mobile.png';
import mobileImage from '../img/logo_mobile.png';

const HomeNavi = () => {
  const [imageSrc, setImageSrc] = useState(defaultImage);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 480) {
        setImageSrc(mobileImage);
      } else if (window.innerWidth <= 768) {
        setImageSrc(tabletImage);
      } else {
        setImageSrc(defaultImage);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // вызов при монтировании для инициализации

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '2%' }}>
        <div>

        </div>
        <div>
            <img src={imageSrc} alt="Responsive" />
        </div>
        <div>

        </div>
        
    </div>
  
  );
};

export default HomeNavi;
