// src/Game.js
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/custom.css';
// import santaImage from '../img/santa.png'; 
import Header from '../components/Header';
import Timer from '../components/Timer';

import defaultBackground from '../img/game-3/Desktop.png';
import tabletBackground from '../img/game-3/Tablet.png';
import mobileBackground from '../img/game-3/Mobile.png';

import santaImage1 from '../img/game-3/santa1.png';
import santaImage2 from '../img/game-3/santa2.png';
import santaImage3 from '../img/game-3/santa3.png';
import santaImage4 from '../img/game-3/santa4.png';
import santaImage5 from '../img/game-3/santa5.png';
import tempSanta from '../img/santa.png';
import OrientationNotification from '../components/PhoneOrientation';
import Preloader from '../components/PreLoader';

const Game = ({ onAllSantsFound, onTimerUp }) => {
    const [backgroundImage, setBackgroundImage] = useState(defaultBackground);
    const [visibleSanta, setVisibleSanta] = useState(null)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 860);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);
    const [isLaptop, setIsLaptop] = useState(window.innerWidth <= 1600);
    // const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1920);
    const [loading, setLoading] = useState(true); // Добавьте состояние для отслеживания загрузки
    const [showPreloader, setShowPreloader] = useState(true); // Добавьте состояние для отслеживания отображения прелоадера

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 860);
            setIsTablet(width <= 1024);

            if (window.innerWidth <= 860) {
                setBackgroundImage(mobileBackground);
            } else if (window.innerWidth <= 1024) {
                setBackgroundImage(tabletBackground);
            } else if (window.innerWidth <= 1600) {
                // setBackgroundImage(laptopBackground);
            } else {
                setBackgroundImage(defaultBackground);
            }
        };

        // Вызов при монтировании и обновлении
        handleResize();

        // Подписка и отписка на событие изменения размера окна
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const divGame = {
        backgroundColor: 'black',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100vw',
    };

    const history = useHistory();
    const [santas, setSantas] = useState([
        { id: 1, visibility: true, tempVisible: false, desktopTop: '76vh', desktopLeft: '53.5vw', laptopTop: '78vh', laptopLeft: '54.5vw', tabletTop: '73.5vh', tabletLeft: '53.2vw', mobileTop: '74.5vh', mobileLeft: '53.5vw', width: '7vw', height: 'auto', img: santaImage1, tempImg: tempSanta },
        { id: 2, visibility: true, tempVisible: false, desktopTop: '80.5vh', desktopLeft: '37.9vw', laptopTop: '82vh', laptopLeft: '36.5vw', tabletTop: '78vh', tabletLeft: '39vw', mobileTop: '78vh', mobileLeft: '38vw', width: '2vw', height: 'auto', img: santaImage2, tempImg: tempSanta },
        { id: 3, visibility: true, tempVisible: false, desktopTop: '81.8vh', desktopLeft: '9.5vw', laptopTop: '83vh', laptopLeft: '4vw', tabletTop: '80vh', tabletLeft: '13vw', mobileTop: '80.2vh', mobileLeft: '9.5vw', width: '3vw', height: 'auto', img: santaImage3, tempImg: tempSanta },
        { id: 4, visibility: true, tempVisible: false, desktopTop: '80vh', desktopLeft: '78.8vw', laptopTop: '81vh', laptopLeft: '83vw', tabletTop: '79vh', tabletLeft: '76vw', mobileTop: '79vh', mobileLeft: '79vw', width: '1.7vw', height: 'auto', img: santaImage4, tempImg: tempSanta },
        { id: 5, visibility: true, tempVisible: false, desktopTop: '47.5vh', desktopLeft: '37.5vw', laptopTop: '50vh', laptopLeft: '36vw', tabletTop: '46vh', tabletLeft: '38.5vw', mobileTop: '36.5vh', mobileLeft: '37vw', width: '2.2vw', height: 'auto', img: santaImage5, tempImg: tempSanta },
    ]);

    const [santaData, setSantaData] = useState([
                {
                    image: santaImage1,
                    id: 1,
        
                    style: {
                        left: 'calc(6.2vw)',
                        bottom: 'calc(11.3vw)',
                        width: '8vw'
                    },
                    view: 'right'
                }, {
                    image: santaImage2,
                    id: 2,
        
                    style: {
                        left: 'calc(78vw)',
                        bottom: 'calc(2.5vw)',
                        width: '6vw'
                    },
                    view: 'left'
                },
                {
                    image: santaImage3,
                    id: 3,
        
                    style: {
                        left: 'calc(20.6vw)',
                        bottom: 'calc(21.6vw)',
                        width: '4vw',
                    },
                    view: 'right'
                },
        
                {
                    image: santaImage4,
                    id: 4,
        
                    style: {
                        left: 'calc(79vw)',
                        bottom: 'calc(25vw)',
                        width: '5vw',
                    },
                    view: 'left'
                }, {
                    image: santaImage5,
                    id: 5,
        
                    style: {
                        left: 'calc(40.2vw)',
                        bottom: 'calc(18vw)',
                        width: '2vw'
                    },
                    view: 'left'
                }
        
            ])

    useEffect(() => {
        const timer = setTimeout(() => {
            onTimerUp();
        }, 20000); // Таймер на 1 минуту

        return () => clearTimeout(timer);
    }, [onTimerUp]);

    const [timeLeft, setTimeLeft] = useState(20);

    useEffect(() => {
        if (santas.every(santa => !santa.visibility)) {
            onAllSantsFound();
            history.push('/form');
        }
    }, [santas, onAllSantsFound, history]);

    const santaStyle = {
        position: 'absolute',
    };

    const handleSantaClick = id => {
        setSantas(santas => {
            return santas.map(santa => {
                if (santa.id === id) {
                    // делаем текущий Санта невидимым
                    const updatedSanta = { ...santa, visibility: false };

                    // таймер для отображения временного изображения
                    setTimeout(() => {
                        setSantas(currentSantas => currentSantas.map(s =>
                            s.id === id ? { ...s, tempVisible: false } : s
                        ));
                    }, 2000); // Исчезнет через 1 секунду

                    return { ...updatedSanta, tempVisible: true };
                }
                return santa;
            });
        });
        setIsMobile(window.innerWidth <= 860);
        setIsTablet(window.innerWidth <= 1024);
        setIsLaptop(window.innerWidth <= 1600);
    };

    const handleSanta = (item) => {
        setSantaData(santaData.filter(i => i.id !== item.id));
        setVisibleSanta(item)
        setTimeout(() => {
            setVisibleSanta(null)
        }, 1000);
    }


    useEffect(() => {
        if (!santaData.length) {
            history.push('/form-third')
        }
    }, [santaData.length])

    useEffect(() => {
        // Ваши текущие эффекты...
        
        // Пример с задержкой для имитации загрузки данных
        const fetchData = async () => {
          try {
            await new Promise(resolve => {
                // Загрузка изображений сант
                const santaImages = [santaImage1, santaImage2, santaImage3, santaImage4, santaImage5];
                santaImages.forEach(imgSrc => {
                    const img = new Image();
                    img.src = imgSrc;
                    img.onload = () => resolve();
                });
    
                // Загрузка заднего фона
                const backgroundImg = new Image();
                backgroundImg.src = defaultBackground;
                backgroundImg.onload = () => resolve();
            });
    
            setLoading(false);
    
            setTimeout(() => {
                setShowPreloader(false);
            }, 2000);
          } catch (error) {
            console.error('Ошибка загрузки данных:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
      if (showPreloader) {
        // Если нужно показать прелоадер, отобразите его
        return <Preloader />;
      }


    return (
        < >
        <OrientationNotification/>
            <div className='background-wrapper'>
                <div className='background-block'>
                    <img className='background-image' src={defaultBackground} />
                    {santaData.map((item, index) => {
                        return visibleSanta?.id !== item?.id ? <img className={`image-santa`} style={item.style} src={item.image} alt='' onClick={() => {
                            handleSanta(item)
                        }} /> : null
                    })}
                    {visibleSanta ? <img className='image-santa ' style={{ ...visibleSanta?.style, width: '10vw', transform: `scaleX(${visibleSanta.view === 'left' ? '-1' : '1'})`}} src={tempSanta} alt='' /> : null}
                </div>

            </div>
            <Header timeLeft={timeLeft} foundSantas={santaData.length} />
            <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} onTimeEnd={onTimerUp} />

       </>
    );
};

export default Game;


// const Game = ({ onAllSantsFound, onTimerUp }) => {
//     const [backgroundImage, setBackgroundImage] = useState(defaultBackground);
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= 860);
//     const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

//     useEffect(() => {
//       const handleResize = () => {
//         const width = window.innerWidth;
//         setIsMobile(width <= 860);
//         setIsTablet(width <= 1024);
//         if (window.innerWidth <= 860) {
//           setBackgroundImage(mobileBackground);
//         } else if (window.innerWidth <= 1024) {
//           setBackgroundImage(tabletBackground);
//         } else {
//           setBackgroundImage(defaultBackground);
//         }
//       };
  
//       // Вызов при монтировании и обновлении
//       handleResize();
  
//       // Подписка и отписка на событие изменения размера окна
//       window.addEventListener('resize', handleResize);
//       return () => window.removeEventListener('resize', handleResize);
//     }, []);
//       const divGame = {
//         backgroundColor: 'black',
//         backgroundImage: `url(${backgroundImage})`,
//         backgroundSize: 'contain',
//         backgroundPosition: 'center bottom',
//         backgroundRepeat: 'no-repeat',
//         minHeight: '100vh',
//         width: '100vw',
//       };

//     const history = useHistory();
//     const [santas, setSantas] = useState([
//         { id: 1, visibility: true, tempVisible: false, desktopTop: '51vh', desktopLeft: '14.8vw', tabletTop:'41vh', tabletLeft:'16.5vw', mobileTop: '40vh',  mobileLeft: '16.5vw', width: '5.8vw', height: 'auto',   img: santaImage1, tempImg: tempSanta },
//         { id: 2, visibility: true, tempVisible: false, desktopTop: '67.5vh',   desktopLeft: '65.2vw', tabletTop:'58vh',   tabletLeft:'64vw', mobileTop: '57vh',    mobileLeft: '64vw', width: '6.5vw', height: 'auto',   img: santaImage2, tempImg: tempSanta },
//         { id: 3, visibility: true, tempVisible: false, desktopTop: '33.9vh', desktopLeft: '14vw', tabletTop:'24vh', tabletLeft:'15vw',    mobileTop: '22.5vh',  mobileLeft: '15vw',  width: '2.5vw', height: 'auto',   img: santaImage3, tempImg: tempSanta },
//         { id: 4, visibility: true, tempVisible: false, desktopTop: '29vh', desktopLeft: '59vw', tabletTop:'19vh', tabletLeft:'57.6vw',   mobileTop: '16.5vh',  mobileLeft: '57vw', width: '4vw', height: 'auto', img: santaImage4, tempImg: tempSanta },
//         { id: 5, visibility: true, tempVisible: false, desktopTop: '40.5vh', desktopLeft: '23.3vw', tabletTop:'30.5vh',   tabletLeft:'23.5vw', mobileTop: '29vh',    mobileLeft: '23.6vw', width: '1.75vw', height: 'auto', img: santaImage5, tempImg: tempSanta },
//     ]);

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             onTimerUp();
//         }, 600000); // Таймер на 1 минуту

//         return () => clearTimeout(timer);
//     }, [onTimerUp]);

//     const [timeLeft, setTimeLeft] = useState(600);

//     useEffect(() => {
//         if (santas.every(santa => !santa.visibility)) {
//             onAllSantsFound();
//             history.push('/form'); 
//         }
//     }, [santas, onAllSantsFound, history]);
    
//     const handleSantaClick = id => {
//         setSantas(santas => {
//             return santas.map(santa => {
//                 if (santa.id === id) {
//                     // Сначала делаем текущий Санта невидимым
//                     const updatedSanta = { ...santa, visibility: false };

//                     // Устанавливаем таймер для отображения временного изображения
//                     setTimeout(() => {
//                         setSantas(currentSantas => currentSantas.map(s =>
//                             s.id === id ? { ...s, tempVisible: false } : s
//                         ));
//                     }, 1000); // Исчезнет через 1 секунду

//                     return { ...updatedSanta, tempVisible: true };
//                 }
//                 return santa;
//             });
//         });
//         // setIsMobile(window.innerWidth <= 860);
//         // setIsTablet(window.innerWidth <= 1024);
//     };
    

//     return (
//         <div style={divGame}>
//             <Header timeLeft={timeLeft} foundSantas={santas.filter(santa => !santa.visible).length} />
//             <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} onTimeEnd={onTimerUp} />
//             {santas.map(santa => (
//                 <React.Fragment key={santa.id}>
//                     {santa.visibility && (
//                         <img
//                             className='santa-img'
//                             src={santa.img}
//                             alt="Santa"
//                             style={{
//                                 top: isMobile ? santa.mobileTop : isTablet ? santa.tabletTop : santa.desktopTop,
//                                 left: isMobile ? santa.mobileLeft : isTablet ? santa.tabletLeft : santa.desktopLeft,
//                                 width: santa.width,
//                                 height: santa.height,
//                                 visibility: santa.visibility ? 'visible' : 'hidden'
//                             }}
//                             onClick={() => handleSantaClick(santa.id)}
//                         />
//                     )}
//                     {santa.tempVisible && (
//                         <img
//                             className='temp-santa-img'
//                             src={santa.tempImg}
//                             alt="Temporary Santa"
//                             style={{
//                                 top: isMobile ? santa.mobileTop : isTablet ? santa.tabletTop : santa.desktopTop,
//                                 left: isMobile ? santa.mobileLeft : isTablet ? santa.tabletLeft : santa.desktopLeft,
//                                 width: santa.width,
//                                 height: santa.height,
//                                 visibility: santa.tempVisible ? 'visible' : 'hidden'
//                             }}
//                         />
//                     )}
//                 </React.Fragment>
//             ))}

//         </div>
//     );
// };

// export default Game;
