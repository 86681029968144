// src/Timer.js
import React, { useEffect } from 'react';

const Timer = ({ timeLeft, setTimeLeft, onTimeEnd }) => {
    useEffect(() => {
        if (timeLeft === 0) {
            onTimeEnd();
            return;
        }

        const timerId = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearTimeout(timerId);
    }, [timeLeft, setTimeLeft, onTimeEnd]);

    // Timer теперь не отображает время, так как это делает Header
    return null;
};

export default Timer;

// import React, { useState, useEffect } from 'react';

// const Timer = ({ onTimeEnd }) => {
//     const [seconds, setSeconds] = useState(60);

//     useEffect(() => {
//         if (seconds === 0) {
//             onTimeEnd();
//             return;
//         }

//         const intervalId = setInterval(() => {
//             setSeconds(seconds - 1);
//         }, 1000);

//         return () => clearInterval(intervalId);
//     }, [seconds, onTimeEnd]);

//     return <div>Время: {seconds}</div>;
// };

// export default Timer;
