import React from 'react';
import DataTable from '../pages/DataTable';


const Table = () => {

    return(
        <div>
      <h1>Table Example</h1>
      <DataTable/>
    </div>
    );

};

export default Table;
